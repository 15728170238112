import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Stack
} from "@chakra-ui/react";
import {Select} from "chakra-react-select";
import React, {useEffect, useState} from "react";
import axios from 'axios';
import {toast} from "react-toastify";
import {CheckIcon, SpinnerIcon, WarningIcon} from "@chakra-ui/icons";
import {FaRegCopy, IoIosArrowBack, IoIosInformationCircleOutline, MdDelete} from "react-icons/all";
import {useQuery} from '@tanstack/react-query';
import paymentIdIcon from "../../../images/payment-id-icon.png";
import paymentIdQr from "../../../images/payment-id-qr.png";
import {FileUploader} from "react-drag-drop-files";
import {Tooltip} from "react-tooltip";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

const FormArea = ({formRef, successMessage, credentials}) => {
  const { isPending, isError , data: formData } = useQuery({
    queryKey: ['registration-form-data'],
    queryFn: () =>
        fetch(process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/content/registration').then((res) =>
            res.json(),
        ),
    retry: 2,
  });

  const earlyBirdOfferDate = new Date("2024-09-22T23:59:59");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const alumniMembershipFee = 200;
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formPage, setFormPage] = useState(0);
  const [uniqueWhatsapp, setUniqueWhatsapp] = useState(false);
  const [registrationResponse, setRegistrationResponse] = useState(null);
  const [paymentFile, setPaymentFile] = useState(null);
  const [membershipIdValidation, setMembershipIdValidation] = useState("");
  const [emailValidation, setEmailValidation] = useState({
    isValid: -1,
    errorMessage: "",
  });
  const [formState, setFormState] = useState({
    full_name: "",
    email: "",
    contact_number: "",
    stream_id: 0,
    college_id: 0,
    passed_out_year_id: 0,
    food_preference_id: 0,
    accompany_count: 0,
    membership_type_id: 0,
    membership_id: "",
    whatsapp_number: "",
    att_status_id: 1,
  });

  const calculatePaymentInformation = () => {
    // Don't try to calculate if form data is not loaded
    if (!formData) return;

    // Getting the details which affect the payment amount
    const yearName = formState.passed_out_year_id;
    const membershipType = formState.membership_type_id;

    // If any of the details not yet selected, return
    if (!yearName || !membershipType) return;

    // Get the payment config object from the form data
    const paymentConfig = formData.amount.find((config) => config.membership_type_id === formState.membership_type_id && config.passed_out_year_id === formState.passed_out_year_id);

    // If payment config not found, return
    if (!paymentConfig) {
      setPaymentDetails(null);
      return;
    }

    // Check if offer is valid
    const isOfferValid = new Date() <= earlyBirdOfferDate;

    // Calculate the payment amount
    const membershipFee = membershipType === 1 ? 0 : alumniMembershipFee;
    const paymentAmount = paymentConfig["base_fee"] + paymentConfig["penalty"] + paymentConfig["accompany_fee"] * formState.accompany_count;
    const discountAmount = isOfferValid ? paymentAmount - paymentConfig["discount"] - paymentConfig["penalty"] : paymentAmount;

    let breakUp = [{name: "Registration Fee", amount: `₹${paymentConfig["base_fee"] - membershipFee + paymentConfig["penalty"]}`}];
    if(membershipFee !== 0) breakUp.push({name: "Membership Fee", amount: `₹${membershipFee}`});
    if(formState.accompany_count !== 0) breakUp.push({name: `Accompany Fee x ${formState.accompany_count}`, amount: `₹${paymentConfig["accompany_fee"] * formState.accompany_count}`});
    if(isOfferValid) breakUp.push({name: "Early Bird Offer", amount: `-₹${paymentConfig["discount"] + paymentConfig["penalty"]}`});

    setPaymentDetails({
      actualAmount: paymentAmount,
      finalAmount: discountAmount,
      breakUp
    });
  };

  const validateMemberShipId = () => {
    if(formState.membership_type_id !== 1) {
      if(formState.membership_id !== "") {
        setFormState({
          ...formState,
          membership_id: "",
        });
      }
      setMembershipIdValidation("");
      return;
    }

    if(formState.membership_type_id === 1 && !formState.passed_out_year_id) {
      setMembershipIdValidation("");
      return;
    }

    // Get the last 2 digits of selected year
    const passedOutYear = formData.passed_out_years.filter((year) => year.year_id === formState.passed_out_year_id)[0]?.year_name.toString().slice(2);


    if(formState.membership_id.slice(0, 5) !== "MAA" + passedOutYear) {
      setMembershipIdValidation("Enter a valid Membership ID")
    } else {
      setMembershipIdValidation("");
    }
  };

  useEffect(() => {
    // Validate membership ID based on the membership type and year
    validateMemberShipId();

    // Calculate the payment information
    calculatePaymentInformation();
  }, [formState]);

  const inputHandler = (event) => {
    if ((event.target.name.toString().includes("id") && event.target.name !== "membership_id") || event.target.name === "accompany_count") {
      const value = parseInt(event.target.value.toString());

      setFormState({
        ...formState,
        [event.target.name]: isNaN(value) ? 0 : value,
      });
    } else {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      });
    }
  };

  const emailCheck = (event) => {
    const email = event.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(email)) {
      setEmailValidation({
        isValid: -2,
        errorMessage: "",
      });

      axios.post(
          process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/validate/email',
          {
            "email": email
          }
      ).then((response) => {
        const {valid = true} = response.data;
        setEmailValidation({
          isValid: valid ? 1 : 0,
          errorMessage: valid ? "" : "Email already registered",
        });
      }).catch((error) => {
        if (error.response && error.response.status === 403) {
          const {valid = true} = error.response.data;
          setEmailValidation({
            isValid: valid ? 1 : 0,
            errorMessage: valid ? "" : "Email already registered",
          });
        } else {
          console.log(error);
          toast.error("Error in validating email, Please refresh and try again.");
        }
      });
    } else {
      setEmailValidation({
        isValid: 0,
        errorMessage: "Please enter a valid email address",
      });
    }
  }

  const createCredentials = (data) => {
    let name = data.full_name;
    let userName = data.email;
    let password = data.email.slice(0,4) + data.contact_number.slice(-4);

    return {name, userName, password};
  }

  const submitHandler = (event) => {
    event.preventDefault();

    // disable the button
    setIsSubmitLoading(true);

    // create form data
    const formSubmitData = {
      ...formState,
      total_amount: paymentDetails?.finalAmount ?? 0,
      "whatsapp_number": !uniqueWhatsapp ? formState.contact_number : formState.whatsapp_number,
    }

    // send the data to the backend
    axios.post(
        process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/registration/create',
        formSubmitData
    ).then((response) => {
      console.log(response.data);
      setRegistrationResponse(response.data);
      credentials(createCredentials(formState));
      setFormPage(1);
    }).catch((e) => {
      console.log(e);
      toast.error("Error during registration, Please try again.");
    }).finally(() => {
      setIsSubmitLoading(false);
    });
  }

  if (isPending) {
    return <div className="form-area">
      <div className="loader">
        Loading...
      </div>
    </div>;
  }

  if (isError) {
    toast.error("Error in fetching form data, Please refresh and try again.");
    return <div className="form-area">
      <div className="loader">
        Something went wrong, Please refresh and try again.
      </div>
    </div>;
  }

  const priceToolTip = () => (
      <Tooltip id="price-breakup-tooltip" className="price-breakup-tooltip">
        {
          paymentDetails.breakUp.map(({name, amount}) => (
              <div key={name} className="price-line">
                <div>{name}</div>
                <div>{amount}</div>
              </div>
          ))
        }
      </Tooltip>
  );

  const paymentSummarySection = () => (
      <>
        {paymentDetails !== null && <div data-tooltip-id="price-breakup-tooltip" className="price-summary">
          <IoIosInformationCircleOutline/>
          <span>Summary</span>
        </div>}
        {paymentDetails !== null && priceToolTip()}
        {paymentDetails?.actualAmount > paymentDetails?.finalAmount &&
            <span className="actual-amount">
                  <span>₹{paymentDetails?.actualAmount ?? 0}</span>
                </span>
        }
        <div className="pay-amount">₹{paymentDetails?.finalAmount ?? 0}</div>
      </>
  );

  const formSection = (display) => (
      <Stack style={{display: display ? "flex" : "none"}} as={'form'} onSubmit={submitHandler}>
        <div className='form-container'>
          <Stack direction={['column', 'row']} spacing='10px'>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                  required
                  name='full_name'
                  focusBorderColor={"#FDC400"}
                  onChange={inputHandler}
                  type='text'
                  placeholder='Enter your name'
              />
            </FormControl>
            <FormControl isInvalid={emailValidation.isValid === 0}>
              <FormLabel>Email address</FormLabel>
              <InputGroup>
                <Input
                    required
                    name='email'
                    focusBorderColor={"#FDC400"}
                    isInvalid={emailValidation.isValid === 0}
                    errorBorderColor='red.300'
                    onChange={inputHandler}
                    onBlur={emailCheck}
                    type='email'
                    placeholder='Enter email'
                />
                {emailValidation.isValid === 1 ? <InputRightElement children={<CheckIcon color="green.500"/>}/> : <></>}
                {emailValidation.isValid === 0 ? <InputRightElement children={<WarningIcon color="red.500"/>}/> : <></>}
                {emailValidation.isValid === -2 ?
                    <InputRightElement children={<SpinnerIcon color="#FDC400"/>}/> : <></>}
              </InputGroup>
              {emailValidation.isValid === 0 ?
                  <FormErrorMessage>{emailValidation.errorMessage}</FormErrorMessage> : <></>}
            </FormControl>
          </Stack>
          <Stack direction={['column', 'row']} spacing='10px'>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input
                  required
                  name='contact_number'
                  focusBorderColor={"#FDC400"}
                  pattern="[0-9]{10}"
                  onChange={inputHandler}
                  type='tel'
                  placeholder='Enter your Phone Number'
              />
              <FormHelperText>10 digit number without country code</FormHelperText>
            </FormControl>
            {uniqueWhatsapp ?
                <FormControl>
                  <FormLabel>WhatsApp Number</FormLabel>
                  <Input
                      required
                      focusBorderColor={"#FDC400"}
                      onChange={inputHandler}
                      type='tel'
                      pattern="[0-9]{10}"
                      placeholder='Enter your WhatsApp Number'
                      name='whatsapp_number'
                  />
                  <FormHelperText>10 digit number without country code</FormHelperText>
                </FormControl>
                : <></>}
          </Stack>
          <Stack direction={['column', 'row']} spacing='10px'>
            <Checkbox
                name='whatsapp_number'
                focusBorderColor={"#FDC400"}
                defaultChecked
                size='lg'
                _checked={{
                  "& .chakra-checkbox__control": {background: "#FDC400", borderColor: "#FDC400"}
                }}
                onChange={() => setUniqueWhatsapp(!uniqueWhatsapp)}
            >
              Is your Phone Number same as Whatsapp Number?
            </Checkbox>
          </Stack>
          <Stack direction={['column', 'row']} spacing='10px'>
            <FormControl>
              <FormLabel>Membership Type</FormLabel>
              <Select
                  required
                  focusBorderColor={"#FDC400"}
                  onChange={(option) => inputHandler({target: {name: "membership_type_id", value: option.value}})}
                  name='membership_type_id'
                  options={formData.membership_types.map((membership_type) => ({
                    label: membership_type.membership_type,
                    value: membership_type.membership_type_id,
                  }))}
              />
              {formState.membership_type_id === 1 && <FormHelperText>Are you a Maatram Alumni Association Member?</FormHelperText>}
              {formState.membership_type_id === 2 && <FormHelperText>Note: Payment will include alumni membership fee of ₹{alumniMembershipFee} </FormHelperText>}
            </FormControl>
            {formState.membership_type_id === 1 ?
                <FormControl isInvalid={membershipIdValidation !== ""}>
                  <FormLabel>Membership ID</FormLabel>
                  <Input
                      required
                      onChange={inputHandler}
                      focusBorderColor={"#FDC400"}
                      name='membership_id'
                      placeholder='Enter your Membership ID'
                      type='text'
                  />
                  {membershipIdValidation !== "" && <FormErrorMessage>{membershipIdValidation}</FormErrorMessage>}
                </FormControl>
                : <></>}
          </Stack>
          <Stack direction={['column', 'row']} spacing='10px'>
            <FormControl>
              <FormLabel>Educational Stream</FormLabel>
              <Select
                  required
                  onChange={(option) => inputHandler({target: {name: "stream_id", value: option.value}})}
                  name='stream_id'
                  focusBorderColor={"#FDC400"}
                  options={formData.streams.map((stream) => ({
                    label: stream.stream_name,
                    value: stream.stream_id,
                  }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Passed Out In</FormLabel>
              <Select
                  required
                  focusBorderColor={"#FDC400"}
                  onChange={(option) => inputHandler({target: {name: "passed_out_year_id", value: option.value}})}
                  name='passed_out_year_id'
                  options={formData.passed_out_years.map((year) => ({
                    label: year.year_name,
                    value: year.year_id,
                  }))}
              />
            </FormControl>
          </Stack>
          <Stack direction={['column', 'row']} spacing='10px'>
            <FormControl>
              <FormLabel>College Name</FormLabel>
              <Select
                  required
                  focusBorderColor={"#FDC400"}
                  onChange={(option) => inputHandler({target: {name: "college_id", value: option.value}})}
                  name='college_id'
                  options={formData.colleges.map((college) => ({
                    label: college.college_name + ", " + college.college_location,
                    value: college.college_id,
                  }))}
              />
              <FormHelperText>Note: If you don't find your college name select <b>Other, Not in the list</b> in option</FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction={['column', 'row']} spacing='10px'>
            <FormControl>
              <FormLabel>Lunch Preference</FormLabel>
              <Select
                  required
                  focusBorderColor={"#FDC400"}
                  onChange={(option) => inputHandler({target: {name: "food_preference_id", value: option.value}})}
                  name='food_preference_id'
                  options={formData.food_preferences.map((option) => ({
                    label: option.food_preference,
                    value: option.food_preference_id,
                  }))}
              />
              <FormHelperText>Note: Event is in purattasi month.</FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Accompanying count</FormLabel>
              <NumberInput focusBorderColor={"#FDC400"} defaultValue={0}>
                <NumberInputField
                    required
                    name='accompany_count'
                    onChange={inputHandler}
                    pattern="[0-2]{1}"
                    placeholder='Number of people coming with you'
                />
              </NumberInput>
              <FormHelperText>Note: Max of 2 people (Parents/Guardian, Spouse, Kids) only be allowed</FormHelperText>
            </FormControl>
          </Stack>
        </div>
        <div className="submit-section">
          {new Date() <= earlyBirdOfferDate ? <CountdownTimer targetDate={earlyBirdOfferDate} /> : <div />}

          <div className="right-section">
            {paymentSummarySection()}
            <Button type='submit' isDisabled={emailValidation.isValid !== 1 || isSubmitLoading || membershipIdValidation !== ""}>
              Pay & Register
            </Button>
          </div>

        </div>
      </Stack>
  );

  const backButton = () => (
      <div className="back-button" onClick={() => setFormPage(0)}>
        <IoIosArrowBack size={22}/>
        <div>Back to Form</div>
      </div>
  );

  const fileUploadSection = () => (
      <>
        <div className="payment-file-upload-text">Upload the payment screenshot</div>
        <div className="payment-file-upload-section">
          <FileUploader
              handleChange={handleFileChange}
              name="file"
              classes="payment-file-uploader"
              maxSize={3}
              onTypeError={() => toast.error("Please upload a valid image file")}
              onSizeError={() => toast.error("File size should be less than 3MB")}
              types={["JPG", "PNG", "JPEG"]}
          />
        </div>
      </>
  );

  const filePreviewSection = () => (
      <>
        <div className="payment-file-upload-text">Selected Screenshot</div>
        <div className="payment-file-preview-section">
          <img src={URL.createObjectURL(paymentFile)}/>
          <MdDelete onClick={() => setPaymentFile(null)}/>
        </div>
      </>
  );

  const copyUpiIdAction = async () => {
    await navigator.clipboard.writeText("vhemantharajan@okhdfcbank");
    toast.success("UPI ID Copied to clipboard!");
  };

  const handleFileChange = (file) => {
    setPaymentFile(file);
  };

  // Attach payment file to the api and register the user
  const uploadPaymentScreenshot = () => {
    setPaymentLoading(true);
    axios.post(
        process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/registration/file_upload',
        {
          "file": paymentFile,
          "registration_id": registrationResponse?.registration_id ?? "",
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    ).then((response) => {
      if(response.status === 200) {
        successMessage(true);
      }
    }).catch((e) => {
      console.log(e);
      toast.error("Error in uploading payment screenshot, Please try again.");
    }).finally(() => {
      setPaymentLoading(false);
    })
  }

  const paymentSection = (display) => (
      <div style={{display: display ? "block" : "none"}} className="payment-form-container">
        <div className="form-header">
          {backButton()}
        </div>

        <div className="payment-details">
          <div className="payment-details__name">
            <div className="payment-details__name__icon">
              <img src={paymentIdIcon}/>
            </div>
            <div className="payment-details__name__id">Hemantharajan venkatesan</div>
          </div>

          <div className="payment-details__qr">
            <img src={paymentIdQr}/>
            <div className="payment-details__qr__text">Scan the QR code to pay</div>

            <div className="payment-details__qr__id" onClick={copyUpiIdAction}>
              <div className="payment-details__qr__id__title">UPI ID:</div>
              <div className="payment-details__qr__id__content">vhemantharajan@okhdfcbank</div>
              <div className="payment-details__qr__id__copy">
                <FaRegCopy size={18}/>
              </div>
            </div>
          </div>

          <div className="payment-details__amount">
            <div className="payment-details__amount__title">Scan and Pay</div>
            <div className="payment-details__amount__amount">₹{paymentDetails?.finalAmount ?? 0}</div>
          </div>
        </div>

        {paymentFile === null ? fileUploadSection() : filePreviewSection()}

        <div className="submit-section">
          {new Date() <= earlyBirdOfferDate ? <CountdownTimer targetDate={earlyBirdOfferDate} /> : <div />}
          <div className="right-section">
            {paymentSummarySection()}
            <Button isDisabled={paymentFile === null || isSubmitLoading || paymentLoading} onClick={uploadPaymentScreenshot}>Register</Button>
          </div>
        </div>
      </div>
  );

  const thankYouSection = () => (
      <div className="thankyou-section">
        <b>Thank You for Your Interest!</b>
        <div>Registration is now closed. We sincerely appreciate your enthusiasm and support!</div>
        <div>Stay tuned for future updates, and we look forward to seeing you at our next event. If you have any questions, feel free to reach out to us.</div>
        <div>Thank you!</div>
        <div>Best regards,</div>
        <b>Maatram Alumni Association</b>
      </div>
  )

  return (
      <div ref={formRef} className={`form-area ${new Date() <= earlyBirdOfferDate ? "with-offer" : ""}`}>
        {formSection(formPage === 0)}
        {paymentSection(formPage === 1)}
        {/*{thankYouSection()}*/}
      </div>
  );
};

export default FormArea;