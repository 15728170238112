import Navbar from '../../../Components/Nav';
import {Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react';
import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS
import ChangePageTitle from '../../../Components/ChangePageTitle';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {sagaActions} from "../../../store/sagaActions";
import "./Dashboard.scss";
import {CSVLink} from "react-csv";

export default function Dashboard() {
  const dispatch = useDispatch();
  const alumniDetails = useSelector(state => state.alumni);
  const {data: alumniData, error} = alumniDetails;
  const [tableRows, setTableRows] = useState([]);
  const [exportData, setExportData] = useState([]);

  const headers = [
    { label: "Full Name", key: "full_name" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contact_number" },
    { label: "WhatsApp Number", key: "whatsapp_number" },
    { label: "Membership ID", key: "membership_id" },
    { label: "Membership Type", key: "membership_type" },
    { label: "Ticket Status", key: "ticket_status" },
    { label: "Total Amount", key: "total_amount" },
    { label: "Accompany Count", key: "accompany_count" },
    { label: "Food Preference", key: "food_preference" },
    { label: "Passed Out Year", key: "passed_out_year" },
    { label: "College Name", key: "college_name" }
  ];

  useEffect(() => {
    if (alumniData.length === 0 && error === '') {
      dispatch({type: sagaActions.FETCH_ALUMNI_DATA});
    }
  }, [dispatch, alumniData.length, error]);

  useEffect(() => {
    let simplifiedList = [];
    let rows = alumniData.reduce((result, data) => {
          const {
            full_name,
            email,
            whatsapp_number,
            contact_number,
            accompany_count = 0,
            total_amount = 0,
            ticket_status: {description: ticket_status = ""} = {},
            food_preference: {food_preference = ""} = {},
            membership_type: {membership_type = ""} = {},
            attendance_status: {description: attendance_status = ""} = {},
            passed_out_year: {year_name: passed_out_year = ""} = {},
            college: {college_name = ""} = {},
            membership_id = "",
          } = data;

          if (!["InProgress", "Accepted"].includes(ticket_status)) return result;

          simplifiedList.push({
            full_name,
            email,
            whatsapp_number,
            contact_number,
            accompany_count,
            total_amount,
            ticket_status,
            food_preference,
            passed_out_year,
            membership_type,
            membership_id,
            college_name
          });

          if (result.hasOwnProperty(passed_out_year)) {
            const {
              registration: exRegistration,
              paymentReceived: exPaymentReceived,
              accompanyCount: exAccompanyCount,
              vegCount: exVegCount,
              nonVegCount: exNonVegCount,
              attendanceCount: exAttendanceCount,
            } = result[passed_out_year];

            result[passed_out_year] = {
              registration: exRegistration + 1,
              paymentReceived: exPaymentReceived + total_amount,
              accompanyCount: exAccompanyCount + accompany_count,
              vegCount: (food_preference === "Vegetarian") ? exVegCount + 1 : exVegCount,
              nonVegCount: (food_preference === "Non-Vegetarian") ? exNonVegCount + 1 : exNonVegCount,
              attendanceCount: (attendance_status === "Present") ? exAttendanceCount + 1 : exAttendanceCount,
            }
          } else {
            result[passed_out_year] = {
              registration: 1,
              paymentReceived: total_amount,
              accompanyCount: accompany_count,
              vegCount: (food_preference === "Vegetarian") ? 1 : 0,
              nonVegCount: (food_preference === "Non-Vegetarian") ? 1 : 0,
              attendanceCount: (attendance_status === "Present") ? 1 : 0,
            }
          }

          result["total"] = {
            registration: result["total"].registration + 1,
            paymentReceived: result["total"].paymentReceived + total_amount,
            accompanyCount: result["total"].accompanyCount + accompany_count,
            vegCount: (food_preference === "Vegetarian") ? result["total"].vegCount + 1 : result["total"].vegCount,
            nonVegCount: (food_preference === "Non-Vegetarian") ? result["total"].nonVegCount + 1 : result["total"].nonVegCount,
            attendanceCount: (attendance_status === "Present") ? result["total"].attendanceCount + 1 + accompany_count : result["total"].attendanceCount,
            attendanceVegCount: (attendance_status === "Present" && food_preference === "Vegetarian") ? result["total"].attendanceVegCount + 1 +accompany_count : result["total"].attendanceVegCount,
            attendanceNonVegCount: (attendance_status === "Present" && food_preference === "Non-Vegetarian") ? result["total"].attendanceNonVegCount + 1 + accompany_count : result["total"].attendanceNonVegCount,
          };

          return result;
        }, {
          total: {
            registration: 0,
            paymentReceived: 0,
            accompanyCount: 0,
            vegCount: 0,
            nonVegCount: 0,
            attendanceCount: 0,
            attendanceVegCount: 0,
            attendanceNonVegCount: 0,
          }
        }
    );

    setExportData(simplifiedList);
    setTableRows(rows);
  }, [alumniData]);

  if (tableRows.length === 0) {
    return "No Data";
  }

  return (
      <>
        <ChangePageTitle pageTitle='Alumni Connect Panel | All Registrations'></ChangePageTitle>
        <Navbar>
          <div className="dashboard">
            <div className="section">
              <div className="metric-row">
                <div className="metric-box">
                  Registration Count
                  <div>{tableRows["total"].registration}</div>
                </div>
                <div className="metric-box">
                  Total Payment
                  <div>₹{tableRows["total"].paymentReceived}</div>
                </div>
                <div className="metric-box">
                  Veg Count
                  <div>{tableRows["total"].vegCount}</div>
                </div>
                <div className="metric-box">
                  Non Veg Count
                  <div>{tableRows["total"].nonVegCount}</div>
                </div>
                <div className="metric-box">
                  Accompany Count
                  <div>{tableRows["total"].accompanyCount}</div>
                </div>
                <div className="metric-box">
                  Present Count
                  <div>{tableRows["total"].attendanceCount}</div>
                </div>
                <div className="metric-box">
                  Present Veg Count
                  <div>{tableRows["total"].attendanceVegCount}</div>
                </div>
                <div className="metric-box">
                  Present Non Veg Count
                  <div>{tableRows["total"].attendanceNonVegCount}</div>
                </div>
              </div>
            </div>
            <TableContainer className="split-table">
              <Table variant="simple">
                <Thead>
                  <Tr className="row">
                    <Th className="cell">Year</Th>
                    <Th className="cell">Reg Count</Th>
                    <Th className="cell">Payment</Th>
                    <Th className="cell">Acc Count</Th>
                    <Th className="cell">Veg Count</Th>
                    <Th className="cell">Non Veg Count</Th>
                    <Th className="cell">Att Count</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.keys(tableRows).map((rowKey) => {
                    return (
                        <Tr className="row">
                          <Td className="cell">{rowKey}</Td>
                          <Td className="cell">{tableRows[rowKey].registration}</Td>
                          <Td className="cell">{tableRows[rowKey].paymentReceived}</Td>
                          <Td className="cell">{tableRows[rowKey].accompanyCount}</Td>
                          <Td className="cell">{tableRows[rowKey].vegCount}</Td>
                          <Td className="cell">{tableRows[rowKey].nonVegCount}</Td>
                          <Td className="cell">{tableRows[rowKey].attendanceCount}</Td>
                        </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <CSVLink data={exportData} headers={headers} filename={"registration-data.csv"}>
              <div className="report-button">Download Report</div>
            </CSVLink>
          </div>
        </Navbar>
      </>
  );
}
